<template>
  <div>
    <!-- pic33AE0065A36706B5DCDD875A592BD529 -->
    <img class="rounded img-large" src="@/assets/news-network/lu-preorder.jpg">
    <p>
      LEGO® Universe pre-ordering opens on May 14th!
    </p>
    <p>
      Fans who pre-order the game will get a FREE limited edition LEGO Universe minifigure. These exclusive space explorer minifigures are sure to become collector's items, and giving them away is one way we're saying "Thanks!" to fans who order the game before Live One liftoff!
    </p>
    <p>
      Find out more about what fans will receive for pre-ordering LEGO Universe
      <router-link :to="{ name: 'preorder' }">here</router-link>!
    </p>
  </div>
</template>
